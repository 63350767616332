import Vue from 'vue'
import VueRouter from 'vue-router'
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import(/* webpackChunkName: "about" */ '../views/business.vue')
  },
  // 案例
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "about" */ '../views/case.vue')
  },
    // 关于
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
    // 联系
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },

]
const router = new VueRouter({
  routes,
})

router.afterEach((to, from) => {
  document.querySelector('#app').scrollTop=0
  let mask=document.querySelector('.mask');
  let loading=document.querySelector('.mask .mask-cont-img');
  mask.style.display='block';
  setTimeout(()=>{
    loading.classList.add('mask-cont-imgs')
  },10)
  setTimeout(()=>{
    loading.classList.remove('mask-cont-imgs')
    mask.style.display='none'
  },2510)

})
export default router
