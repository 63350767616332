<template>
  <div id="app">
    <div class="mask">
      <div class="mask-cont">
        <div class="mask-cont-img">
          <img src="../src/assets/img/loading.png" class="loading" id="loading">
        </div>
      </div>

    </div>
    <div ref="header" class="header" :class="[$route.name == 'contact' ? 'header-contact' : '']">
      <div class="header-left">
        <img src="./assets/img/logo.png">
      </div>
      <div class="header-right">
        <div class="header-nav" v-if="$route.name">
          <div v-for="(item, index) in $t('navData')" class="nav" @click="toPage(item, index)"
            :class="[$route.name.toLocaleUpperCase() == item.id ? 'nav-aictive' : '']">
            {{ item.name }}
          </div>
        </div>
        <div class="language">
          <div class="language-box" @click="languageChange('en')" :class="[$i18n.locale == 'en' ? 'language-aictive' : '']">
            <img src="./assets/img/en.png">
            <span>EN</span>
          </div>
          <div class="language-box1">/</div>
          <div class="language-box" @click="languageChange('th')" :class="[$i18n.locale == 'th' ? 'language-aictive' : '']">
            <img src="./assets/img/th.png">
            <span>TH</span>
          </div>
          <!-- <div class="language-box1">/</div>
          <div class="language-box" @click="languageChange('cn')" :class="[$i18n.locale == 'cn' ? 'language-aictive' : '']">
            <img src="./assets/img/cn.png">
            <span>CN</span>
          </div> -->
        </div>
      </div>
    </div>
    <router-view />
    <div class="footer">
      <div class="footer-top">
        <div class="footer-box">
          <img src="./assets/img/logo1.png" class="logo">
        </div>
        <div class="footer-box">
          <div class="footer-text1">
            {{ $t('footer.CONTACT.name') }}
          </div>
          <div class="footer-text2">
            <img src="./assets/img/@.png" style="width: 23px;height: 23px">
            <span>
              {{ $t('footer.CONTACT.text1') }}
            </span>
          </div>
          <div class="footer-text2">
            <img src="./assets/img/1.png" style="width: 28px;height: 28px">
            <span>
              {{ $t('footer.CONTACT.text2') }}
            </span>
          </div>
          <!-- <div class="footer-text2">
            <img src="./assets/img/2.png" style="width: 28px;height: 21px">
            <span>
              {{ $t('footer.CONTACT.text3') }}
            </span>
          </div>
          <div class="footer-text2">
            <span style="width: 35px;float: left;height: 20px;margin-right: 20px"></span>
            <span>
              {{ $t('footer.CONTACT.text4') }}
            </span>
          </div>
          <div class="footer-text2">
            <span style="width: 35px;float: left;height: 20px;margin-right: 20px"></span>
            <span>
              {{ $t('footer.CONTACT.text5') }}
            </span>
          </div> -->
        </div>
        <div class="footer-box">
          <div class="footer-text1">
            {{ $t('footer.MENU.name') }}
          </div>
          <div class="footer-nav">
            <div v-for="(item, index) in $t('navData')" class="nav" @click="toPage(item, index)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="footer-box">
          <div class="footer-text1">
            {{ $t('footer.LINK.name') }}
          </div>
          <div class="footer-text2">
            <a :href="$t('footer.LINK.url1')" target="_blank">
              {{ $t('footer.LINK.text1') }}
            </a>

          </div>
          <div class="footer-text2">
            <a :href="$t('footer.LINK.url2')" target="_blank">
              {{ $t('footer.LINK.text2') }}
            </a>
          </div>
          <div class="footer-text2">
            <a :href="$t('footer.LINK.url3')" target="_blank">
              {{ $t('footer.LINK.text3') }}
            </a>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        {{ $t('footer.text') }}
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      navIndex: 0,
      befortop: 0
    }
  },
  mounted() {
    this.browserRedirect()
    this.$router.push('/');
    window.addEventListener('scroll', this.handleScroll, true);

  },
  methods: {
    browserRedirect() { //判断终端
      var u = window.navigator.userAgent;
      let mobile = !!u.match(/AppleWebKit.*Mobile.*/);//是否为移动终端
      let currentHref = window.location.href.indexOf('mobile');
      if (mobile) {
        console.log('mobile', currentHref)
        //跳转移动端页面
        if (currentHref == -1) {
          window.location.href = "http://www.pandamobomcn.com/mobile";
        }
      } else {
        console.log('pc', currentHref)
        //跳转pc端页面
        if (currentHref != -1) {
          window.location.href = "http://www.pandamobomcn.com";
        }
      }
    },
    handleScroll() {
      var aftertop = document.querySelector('#app').scrollTop;

      if (aftertop - this.befortop > 0) {
        // 向下
        this.$refs.header.classList.remove('header-top')
        this.$refs.header.classList.add('header-bottom')
      } else {
        // 向上
        this.$refs.header.classList.remove('header-bottom')
        if (aftertop > 20) {
          this.$refs.header.classList.add('header-top')
        } else {
          this.$refs.header.classList.remove('header-top')
        }
      }
      this.befortop = aftertop;

    },
    languageChange(type) {
      this.$i18n.locale = type;  //设置中英文模式
      localStorage.setItem('languageSet', this.$i18n.locale)   //将用户设置存储到localStorage以便用户下次打开时使用此设置
    },
    toPage(item, index) {
      this.navIndex = index
      this.$router.push(item.url)
    }
  }
}
</script>

<style lang="less">
.mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #292929;
  display: none;

  .mask-cont {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    background: url("assets/img/loadings.png") no-repeat 0;
    background-size: cover;
    height: 366px;
  }

  .mask-cont-img {
    height: 0;
    transition: all 2.5s ease;
    overflow: hidden;
    opacity: 0;
  }

  .mask-cont-imgs {
    opacity: 1;
    height: 366px;
  }

  .loading {
    width: 340px;
    height: 366px;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  height: 100%;
  position: relative;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.header {
  box-sizing: border-box;
  width: 99%;
  border-bottom: 1px solid white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  padding: 12px 74px;
  align-items: center;
  transition: top .5s;

  .header-left {
    img {
      width: 73px;
      height: 79px;
    }
  }

  .header-right {
    display: flex;

    .header-nav {
      margin-right: 100px;
      display: flex;
      font-size: 16px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;

      .nav {
        margin: 0 25px;
        cursor: pointer;
      }

      .nav-aictive {
        color: #F7941F;
      }
    }
  }

  .language {
    display: flex;
    align-items: center;
    font-size: 16px;

    .language-box1 {
      margin: 0 5px;
      color: white;
    }

    .language-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: white;

      img {
        width: 19px;
        height: 19px;
        margin-right: 7px;
      }
    }

    .language-aictive {
      color: #F7941F !important;
    }
  }
}

.header-bottom {
  top: -108px;
  background: transparent;
}

.header-top {
  top: 0;
  background: rgba(59, 59, 59, .6);
  border: none;
}

.header-contact {
  border-color: #222;

  .header-right {
    .header-nav {
      color: #222;
    }

    .language {
      .language-box {
        color: #222;
      }
    }
  }

}

.footer {
  padding-top: 100px;
  background: #010020;
  color: white;

  .footer-top {
    //padding-left: 74px;
    display: flex;
    justify-content: space-around;

    .footer-box {
      .logo {
        width: 261px;
        height: 282px;
      }

      .footer-text1 {
        font-size: 80px;
        margin-bottom: 50px;
      }

      .footer-text2 {
        font-size: 20px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }

      .footer-nav {
        div {
          margin-bottom: 22px;
          cursor: pointer;
        }
      }

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .footer-bottom {
    font-size: 16px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    border-top: 1px solid white;
    padding: 37px 0;
    margin-top: 240px;
  }
}</style>
