module.exports = {
  language: {
    name: "th",
  },
  // 导航数据
  navData: [
    {
      name: "หน้าแรก",
      id: "HOME",
      url: "/",
    },
    {
      name: "ธุรกิจ",
      id: "BUSINESS",
      url: "/business",
    },
    {
      name: "ตัวอย่าง",
      id: "CASE",
      url: "/case",
    },
    {
      name: "เกี่ยวกับ",
      id: "ABOUT",
      url: "/about",
    },
    {
      name: "ติดต่อ",
      id: "CONTACT",
      url: "/contact",
    },
  ],
  // 尾部
  footer: {
    CONTACT: {
      name: "ติดต่อ",
      text1: "MCN@pandamobo.com",
      text2: "www.pandamobo.com",
      text3: "liuzilin@pandamobo.com",
      text4: "linxufeng@pandamobo.com",
      text5: "yemeiyi@pandamobo.com",
    },
    MENU: {
      name: "เมนู",
    },
    LINK: {
      name: "ลิงค์",
      text1: "ไป่ตู้",
      url1: "https://www.baidu.com",
      text2: "อเมซอน",
      url2: "https://www.amazon.cn/",
      text3: "แพทยศาสตรบัณฑิต",
      url3: "http://mercurydynamic.67760.com/",
    },
    text: "© บริษัท แพนดาโมโบ เอ็มซีเอ็น อินเตอร์เนชั่นแนล จำกัด",
  },
  // 首页数据
  home: {
    years: {
      text1: "ขึ้นตลาดหุ้น14ปี",
      text2: "มูลค่าตลาด3หมื่นล้านบาท",
      //text3:'ด้วยทรัพยากรที่สมบูรณ์และทีมงานที่มี่ความเชี่ยวชาญ ห่วงโซ่ทุนที่มั่นคง การปฏิบัติการที่มีประสิทธิภาพและได้รับรองจากองค์กรอย่างเป็นทางการ ได้อำนวยบริการที่ดีเยี่ยมให้บริษัทข้ามชาติและเน็ตไอดอลที่เป็นร้อย พยายามกลายโตเป็นMCNที่ดีเลิศระหว่างประเทศที่มีชื่อเสียง'
      text3:
        "ด้วยทรัพยากรที่สมบูรณ์และทีมงานที่มี่ความเชี่ยวชาญ รวมถึงห่วงโซ่ทุนที่มั่นคง &nbsp;&nbsp;&nbsp;&nbsp; ทำให้การปฏิบัติการมีประสิทธิภาพและได้รับรองจากองค์กรอย่างเป็นทางการ ซึ่งได้อำนวยบริการที่ดีเยี่ยมให้บริษัทข้ามชาติและเน็ตไอดอลอีกเป็นร้อย &nbsp;&nbsp;&nbsp;&nbsp; เราพยายามเติบโตกลายเป็นMCNระหว่างประเทศจนมีชื่อเสียงที่ดีเลิศ",
    },
    global: {
      // text1:'ซึ่งเป็นเวทีที่ได้ร่วมมือกับFacebook  Twitter Google  quora  ctiteo  RTB house taboola  Huawei ADs',
      text1:
        "เป็นบริษัทที่ได้ร่วมมือกับแพลตฟอร์มFacebook Twitter Google quora ctiteo RTB house taboola Huawei Ads",
      text2: "การตลาดทั่วโลก",
      // text3:'เป็นเวทีที่มีคุณสมบัติมีเพื่อนร่วมมือที่ดีเยี่ยมอย่างFacebook  Twitter Google  TikTok'
      text3:
        "เป็นบริษัทที่มีคุณสมบัติในการเป็นหุ้นส่วนหลักที่ดีเยี่ยมของสี่แพลตฟอร์มอย่างFacebook Twitter Google TikTok",
    },
    list: {
      text1: "NO.1-การลงทุนส่งมอบทรัพยากรต่างประเทศ",
      text2: "NO.2-โซเชียลมีเดีย+ถ่ายทอดสด",
      text3: "NO.3-การดำเนินการในท้องถิ่นทั่วโลก",
      text4: "NO.4-บริการข้อมูลขนาดใหญ่ของสื่อใหม่",
    },
    memorabilia: {
      name: "การเติบโตของบริษัท",
      now: {
        text1: "NOW",
        text2: "มูลค่าตลาด 30 พันล้านบาทขึ้นไปเมื่อปี 2023",
      },
      2016: {
        text1: "2016",
        text2: "ได้ร่วมมือกับสื่อหลักทั้งหมดเมื่อปี2016",
      },
      2009: {
        text1: "2009",
        text2: "ขึ้นตลาดหุ้นเมื่อปี2009",
      },
    },
    pandaMCN: {
      name: "Pandamobo MCNได้ช่วยKOLหลากหลายคนประสบความสำเร็จ",
      name1: "Pandamobo MCN",

      text1: "",
      text2: "ได้ให้บริการลูกค้ากว่า 1,300คนทั่วโลก",

      text3: "  +",
      text4: "ได้ร่วมมือเชิงลึกกับสื่อออนไลน์กว่า2,600+ร้าน",

      text5: " +",
      text6: "เพิ่มยอดวิวกว่าร้อยล้านให้กับนักไลฟ์",
    },
    map: {
      text1: "การนำสินค้าเข้าถึงทั่วอาเฃียนด้วยtiktok",
      // text2:"จิ่วฉีซอฟต์แวร์ เลขที่หุ้นคือ002279 ซึ่งเป็นบริษัทที่ขึ้นตลาดหุ้นจีน  Pandamobo MCNเป็นบริษัทลูกของมัน  ข้อมูลและการนำสินค้าเป็นสองสินค้าที่มีลักษณะเฉพาะได้เพิ่มมูลค่าการนำสินค้าอย่างเรื่อยๆ ",
      text2:
        'Jiuqi Software (รหัสหุ้น: 002279) เป็นบริษัทจดทะเบียนในตลาดหลักทรัพย์Aของจีน <br>มีการดำเนินงานทั้งด้าน "ข้อมูล + การแนะนำสินค้า รองรับการแนะนำสินค้าด้วยฐานข้อมูลขนาดใหญ่ &nbsp;&nbsp;&nbsp;&nbsp; เผยแพร่มูลค่าข้อมูลการแนะนําสินค้า เพิ่มมูลค่าทางข้อมูลผ่านการแนะนำสินค้าอย่างต่อเนื่อง',
      // text3:'จะช่วยให้ผู้ใช้ค้นหาง่ายขึ้น การให้บริการแม่นยำกว่าและได้ผลสำเร็จที่เลิศกว่า บริษัทจะพยายามช่วยอำนวยบริการแบบดิจิดอล+การนำสินค้าแก่ฝ่ายค้าขายและเน็ตไอดอล',
      text3:
        'ซึ่งทำให้ภาพลักษณ์ของผู้ใช้งานมีความละเอียดมากขึ้น &nbsp;&nbsp; จับคู่ได้แม่นยำยิ่งขึ้น &nbsp;&nbsp;&nbsp; และมีผลลัพธ์ที่มีประสิทธิภาพสูงขึ้น &nbsp;&nbsp;&nbsp; บริษัทมุ่งมั่นที่จะให้บริการ &nbsp;&nbsp; "ดิจิทัล &nbsp;&nbsp; + &nbsp;&nbsp; การแนะนำสินค้า &nbsp;&nbsp; แบบครบวงจรทั่วโลกสำหรับ KOL ผู้จัดจำหน่าย และผลิตภัณฑ์สินค้าต่างๆ',
      text4: "กลยุทธ์การให้บริการ",
      text5:
        "ไม่ว่าจะเป็นสถานการณ์สื่อสังคมต่างประเทศ การเหมาะสมกับมูลค่ายี่ห้อสินค้า ตลอดถึงการหาฝูงชนที่แม่นยำ Pandamobo MCNจะให้แผนกลยุทย์ที่สดวกและข้อเสนอแนะแก่เน็ตไอดอล",
    },
    icon: {
      text1: "และได้ร่วมมือกับหลากหลายแบรนด์ดังที่มีชื่อเสียงทั่วโลก",
      text2: "ติดต่อเรา",
      text3: "มาทำงานร่วมกัน",
    },
  },
  // 业务
  business: {
    years: {
      text1: "ธุรกิจ",
      text2:
        "Pandamobo MCN มีส่วนช่วยให้บล็อกเกอร์มีเอกลักษณ์และโดดเด่นมากยิ่งขึ้น",
    },
    box: {
      text1: "ดาต้าที่ดีเยี่ยม",
      // text2:'ด้วยเวที่สื่อใหม่และโฆษาณาดิจิดอลต่างประเทศอย่างFacebook  Twitter Google  Instagram  tiktok  สร้างเวทีต่างประเทศที่เผยแพร่ ด้วยกลยุทย์ที่เผยแพร่และการส่งมอบโฆษาณาที่ดีสุด ช่วยบริษัทจีนและสินค้าจีนเดินออกไปต่างประเทศ',
      text2:
        "ด้วยแพลตฟอร์มสื่อใหม่ในต่างประเทศ &nbsp;&nbsp; เช่น &nbsp;&nbsp; Facebook Twitter Google Instagram TikTok &nbsp;&nbsp; และโฆษณาดิจิทัลกลางแจ้ง &nbsp; โดยสร้างตำแหน่งการสื่อสารและการประชาสัมพันธ์ในต่างประเทศ &nbsp;&nbsp;&nbsp;&nbsp; ด้วยกลยุทธ์การสื่อสารที่แปลเป็นภาษาท้องถิ่นและตำแหน่งโฆษณาที่ &nbsp;&nbsp;&nbsp;&nbsp; ซึ่งจะช่วยให้บริษัทและนวัตกรรมผลิตภัณฑ์ของจีนก้าวไปสู่ต่างประเทศได้อย่างมีประสิทธิภาพ",
      text3: "แพลตฟอร์มสื่อใหม่ชั้นนำระดับสากล",
      text4: "โฆษณาดิจิทัลกลางแจ้งที่ทรงพลัง",
    },
    list: {
      text1: "สถานีโทรทัศน์กว่า 150 สถานี",
      text2: "สถานีวิทยุกว่า 380 สถานี",
      text3: "เส้นทางรถเมล์กว่า 7680 เส้น",
      text5: "เส้นรถไฟใต้ดินกว่า2000เส้น",
      text6: "หนังสือพิมพ์กว่า5หมื่นเล่ม",
      text7: "นิตยสารกว่า2หมื่นเล่ม",
      text4: "1 ล้านหน้าจอ",
    },
    media: {
      text1: "ทรัพยากรสื่อดิจิตอลoutdoor",
      imgText0: "ไทม์สแควร์นิวยอร์ก ",
      imgText1: "หน้าจอใหญ่ลาสเวกัสฮาร์มอน",
      imgText2: "หน้าจอพิคคาดิลีลอนดอน",
      imgText3: "จอแยกวอกซ์ฮอลในลอนดอน",
      imgText4: "ศูนย์การค้าเวสต์ฟิลด์ ลอนดอน",
      imgText5: "ประตูชัยปารีส",
      imgText6: "หน้าจอย่านธุรกิจลาสฟงเตสในกรุงปารีส",
      imgText7: "ย่านธุรกิจถนนคนเดินของปารีส",
      imgText8: "หน้าจอดิจิตอลขนาดใหญ่ใจกลางกรุงเบอร์ลิน",
      imgText9: "หน้าจอดิจิตอลขนาดใหญ่ใจกลางกรุงแฟรงค์เฟิร์ต",
      imgText10: "สแควร์ยีตาลีโรมสเปน",
      imgText11: "หน้าจอสแควร์ลานซานบาบิลาส",
      imgText12: "หน้าจอใหญ่ซิดนีย์คิงส์ครอส",
      imgText13: "LEDศูนย์การค้าเบิร์กสตรีทเมลเบิร์น",
    },
    release: "การเผยแพร่PR",
  },
  // 案例
  case: {
    years: {
      text1: "ตัวอย่างที่ประสบความสำเร็จ",
      text2: "ได้ช่วยลูกค้าตระหนักถึงคุณค่า",
    },
    social: {
      text1: "โซเชียลมีเดีย + การถ่ายทอดสด",
      text2:
        "สร้างจุดยืนทางอินเทอร์เน็ตบนโซเชียลมีเดีย &nbsp; โต้ตอบพูดคุยกับแฟนๆและสร้างรายได้จากการถ่ายทอดสด",
      // text3:'Pandamobo MCNจะช่วยคุณสร้างโครงสร้างบัญชีสื่อสังคม สคริปต์การถ่ายทอดสด การออกแบบหาตำแหน่ง การเลือกสินค้า การดำเนินแฟนและการวางแผนกิจกรรม จะช่วยกลายเป็นเน็ตไอดอลที่มีชื่อเสียงโดยเร็วสุด',
      text3:
        "Pandamobo MCN ช่วยให้คุณสร้างเมทริกซ์โซเชียลมีเดีย &nbsp; สคริปต์การถ่ายทอดสด &nbsp; การออกแบบหาตำแหน่ง &nbsp; การเลือกผลิตภัณฑ์ &nbsp; เลือกการเข้าถึงของแฟนคลับ &nbsp;&nbsp;&nbsp;&nbsp; และการวางแผนงานต่างๆจน &nbsp;&nbsp; กลายเป็นบล็อกเกอร์ที่สร้างรายได้อย่างรวดเร็ว",
      text4: "หมวดหมู่สินค้าแบรนด์ดัง",
      text5: "หมวดหมู่3Cที่ทันสมัย",
      text6: "หมวดหมู่การบริโภคในชีวิตประจำวัน",
    },
    kol: {
      text1: "รายชื่อร้านค้าธุรกิจ KOL – การจัดวางเนื้อหาและนำเสนอโฆษณา",
      text2: "เพิ่มศักยภาพในการสร้างรายได้ของKOL",
      imgText0:
        "Russia: Katerina Summer (YouTube) 19,352 subscribers https://www.youtube.com/channel/\n UC3PZeV3FCRpfraOZZGjWYHg\n 11-minute full product review\n including link to AliExpress\n promotion pages and promotion\n dates.",
      imgText1:
        "France:InsideCloset\n (Instagram)\n 75.4kfollowers\n https://www.instagram.com/in sidecloset/\n Willincludeproduct\n photography,link,short\n commentonInstagram\n channel.Awaitingapproval andproductfromclient.",
    },
    tiktok: {
      text1: "Assist TikTok Challenge",
      text2:
        "Pandamobo MCNช่วยเผยแพร่การแข่งขันเอเชียนเกมส์อินโดนีเซียยอดวิวทะลุ  2 ล้าน",
      text3: "ยอดวิวทะลุ  2 ล้าน",
      text4: "Pandamobo MCNช่วยเผยแพร่การแข่งขันหยันซีกงลูวของไต้หวัน",
      text5: "ยอดวิวทะลุ 1 แสน",
    },
  },
  // 关于
  about: {
    years: {
      text1: "Pandamobo MCN",
      text2: "ขึ้นตลาดหุ้น14ปี",
    },
    profile: {
      text1: "แนะนำบริษัทโดยย่อ",
      // text2:'ขึ้นตลาดหุ้น14ปี มูลค่าตลาด3หมื่นล้านบาท ด้วยทรัพยากรที่สมบูรณ์และทีมงานที่มี่ความเชี่ยวชาญ ห่วงโซ่ทุนที่มั่นคง การปฏิบัติการที่มีประสิทธิภาพและได้รับรองจากองค์กรอย่างเป็นทางการ ได้อำนวยบริการที่ดีเยี่ยมให้บริษัทข้ามชาติและเน็ตไอดอลที่เป็นร้อย พยายามกลายโตเป็นMCNที่ดีเลิศระหว่างประเทศที่มีชื่อเสียง',
      text2:
        "บริษัทเราได้ขึ้นตลาดหุ้น 14 ปี มีมูลค่าตลาด 3 หมื่นล้านบาท ด้วยทรัพยากรที่สมบูรณ์และทีมงานที่มี่ความเชี่ยวชาญ รวมถึงห่วงโซ่ทุนที่มั่นคง ทำให้การปฏิบัติการมีประสิทธิภาพและได้รับรองจากองค์กรอย่างเป็นทางการ ซึ่งได้อำนวยบริการที่ดีเยี่ยมให้บริษัทข้ามชาติและเน็ตไอดอลอีกเป็นร้อย เราพยายามเติบโตกลายเป็นMCNระหว่างประเทศจนมีชื่อเสียงที่ดีเลิศ",
      text3: "",
      text4: "ได้ให้บริการลูกค้ากว่า 1,300คนทั่วโลก",

      text5: "ได้ร่วมมือเชิงลึกกับสื่อออนไลน์กว่า2,600+ร้าน",
      text6: "",

      text7: "เพิ่มยอดวิวกว่าร้อยล้านให้กับนักไลฟ์",
    },
    memorabilia: {
      text1: "การเติบโตของบริษัท",
      listText0: "ตั้งอยู่ที่ปี1997",
      listText1: "ขึ้นตลาดหุ้นเมื่อปี2009",
      listText2: "ได้ร่วมมือกับสื่อหลักทั้งหมดเมื่อปี2016",
      listText3: "มูลค่าตลาด 30 พันล้านบาทขึ้นไปเมื่อปี 2023",
    },
    honors: {
      text1: "รางวัลและเกียรติยศของอุตสาหกรรม",

      text2: "Google",
      text3: "2016",
      text4: "YEAR",
      text5: "ลูกค้าร่วมมือที่เติบโตเร็วที่สุดในแผ่นดินใหญ่จีน",
      text6: "Google",

      text7: "GMIC",
      text8: "2016",
      text9: "YEAR",
      text10: "บริษัทตลาดที่ได้รับความหวังนานาประเทศ",
      text11: "GMIC",

      text12: "พินธมิตรอีคอมเมิร์ซหัวหนัน",
      text13: "2016",
      text14: "YEAR",
      text15: "ลูกค้าร่วมมือที่ดีเลิศของพินธมิตรอีคอมเมิร์ซหัวหนัน",
      text16: "พินธมิตรอีคอมเมิร์ซหัวหนัน2016",

      text17: "GMIC",
      text18: "2017",
      text19: "YEAR",
      text20: "ตลาดที่รับผลดีสุดภายในปี",
      text21: "GMIC",

      text22: "รางวัลจินหยวน",
      text23: "2017",
      text24: "YEAR",
      text25: "บริษัทที่มีความคิดสร้างสรรค์ตลาดที่สุด",
      text26: "รางวัลจินหยวน",

      text27: "รางวัลหู่เสี้ยว",
      text28: "2018",
      text29: "YEAR",
      text30: "ปี2017-2018ได้รางวัลหู่เสี้ยวขององค์กรรัฐบาล ",
      text31: "รางวัลหู่เสี้ยว",

      text32: "รางวัลสร้างสรรค์การค้าการเงินทั่วโลก",
      text33: "2018",
      text34: "YEAR",
      text35: "รางวัลตลาดเหรียญเงินต่างประเทศ",
      text36: "รางวัลสร้างสรรค์การค้าการเงินทั่วโลก",

      text37: "4Aจีน",
      text38: "",
      text39: "",
      text40: "หน่วยงานสมาชิก4Aจีน",
      text41: "4Aจีน",

      text42: "รางวัลใหญ่นานาชาติ2019ADMEN",
      text43: "2019 ",
      text44: "YEAR",
      text45: "บริษัทตลาดที่มีคุณค่าการค้าที่สุดภายในปี",
      text46: "รางวัลใหญ่นานาชาติ2019ADMEN",

      text47: "รางวัลจินเถาส่าง2019",
      text48: "2019",
      text49: "YEAR",
      text50: "รางวัลตลาดตัวอย่างจินเถาส่าง",
      text51: "รางวัลจินเถาส่าง2019",

      text52: "รางวัลลิงเหมา2020MS",
      text53: "2020",
      text54: "YEAR",
      text55: "เวทีให้บริการตลาดนานาชาติท็อป10",
      text56: "รางวัลลิงเหมา2020MS",

      text57: "ประชุมสุดยอดอีคอมเมิร์ซข้ามประเทศทั่วโลก2020",
      text58: "2020",
      text59: "YEAR",
      text60: "ท็อป50ที่ใช้ดิจิตอลดี",
      text61: "ประชุมสุดยอดอีคอมเมิร์ซข้ามประเทศทั่วโลก2020",
    },
  },
  contact: {
    text1: "วิธีการติดต่อ",
    text2: "liuzilin@pandamobo.com",
    text3: "linxufeng@pandamobo.com",
    text4: "yemeiyi@pandamobo.com",
  },
};
