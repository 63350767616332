import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 适配
import "./utils/flexible";
//中英文切换
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem("languageSet") || "th", //从localStorage里获取用户中英文选择
  messages: {
    th: require("./components/language/th"),
    en: require("./components/language/en"),
    // cn: require("./components/language/cn"),
  },
});
Vue.config.productionTip = false;
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount("#app");
