module.exports = {
  language: {
    name: "en",
  },
  // 导航数据
  navData: [
    {
      name: "HOME",
      id: "HOME",
      url: "/",
    },
    {
      name: "BUSINESS",
      id: "BUSINESS",
      url: "/business",
    },
    {
      name: "CASE",
      id: "CASE",
      url: "/case",
    },
    {
      name: "ABOUT",
      id: "ABOUT",
      url: "/about",
    },
    {
      name: "CONTACT",
      id: "CONTACT",
      url: "/contact",
    },
  ],
  // 尾部
  footer: {
    CONTACT: {
      name: "CONTACT",
      text1: "MCN@pandamobo.com",
      text2: "www.pandamobo.com",
      text3: "liuzilin@pandamobo.com",
      text4: "linxufeng@pandamobo.com",
      text5: "yemeiyi@pandamobo.com",
    },
    MENU: {
      name: "MENU",
    },
    LINK: {
      name: "LINK",
      text1: "BAIDU",
      url1: "https://www.baidu.com",
      text2: "AMAZON",
      url2: "https://www.amazon.cn/",
      text3: "MD",
      url3: "http://mercurydynamic.67760.com/",
    },
    text: "© Copyright 2023 - Pandamobo MCN International Pte Ltd - All Rights Reserved.",
  },
  // 首页数据
  home: {
    years: {
      text1: "Listed for 14 years ",
      text2: "Market cap $30 billion ",
      text3:
        "MCN\n Listed for 14 years, market cap of $30 billion, close cooperation with TikTok for 5 years\n With rich resources and a professional team, a stable capital chain, more efficient execution, and official authority certification, we provides more excellent and reliable services for hundreds of international enterprises and high-quality talents. Our goal is to build an industry-leading international MCN brand.",
    },
    global: {
      text1:
        "A company that has Facebook, Twitter, Google, Quora, Ctiteo, RTB House, Taboola, Huawei Ads platforms at the same time",
      text2: "Global Marketing",
      text3:
        "A company that has the core quality partner qualification of Facebook, Twitter, Google, TikTok four major platforms ",
    },
    list: {
      text1: "NO.1-Overseas resource delivery",
      text2: "NO.2-Social media + live streaming",
      text3: "NO.3-Global localization operation",
      text4: "NO.4-New media big data service",
    },
    memorabilia: {
      name: "Chronicle",
      now: {
        text1: "NOW",
        text2: "Market value of ฿30 billion+ in 2023",
      },
      2016: {
        text1: "2016",
        text2: "Reached cooperation with all mainstream media in 2016",
      },
      2009: {
        text1: "2009",
        text2: "Listed in 2009",
      },
    },
    pandaMCN: {
      name: "Pandamobo MCN helps more KOLs achieve success",
      name1: "Pandamobo MCN",
      text1: "family",
      text2: "global customers ",
      text3: "  +",
      text4: "online in-depth cooperation media",
      text5: "million  +",
      text6: "increase in influencers exposure",
    },
    map: {
      text1:
        "Together with TikTok, we will expand to the Southeast Asian market with live streaming e-commerce.",
      text2:
        'A wholly-owned subsidiary of Jiuqi Software (stock code: 002279), a listed company in China\'s A-share market,With the unique combination of "data + e-commerce" as its core features, it has achieved continuous value-added selling,',
      text3:
        'Offer more detailed user profiles, improved matching accuracy, and optimized results.\n Committed to providing KOLs, sellers and brand owners with one-stop "digital + e-commerce" service worldwide.',
      text4: "Customized Strategy",
      text5:
        "Stay updated with the latest trends in overseas social media and ensure alignment with brand values.\n Our expertise ranges from material selection to precise audience targeting.\n Pandamobo MCN can provide influencers with one-stop strategic plans.",
    },
    icon: {
      text1: "Cooperate with many well-known global brands",
      text2: "Contact  us",
      text3: "LET'S WORK TOGETHER",
    },
  },
  // 业务
  business: {
    years: {
      text1: "business",
      text2: "Pandamobo MCN helps customers \n stand out",
    },
    box: {
      text1: "High-quality traffic",
      text2:
        "By relying on Facebook, Twitter, Google, Instagram, TikTok and other overseas new media platforms and outdoor digital advertising, to create overseas communication and publicity positions, to help Chinese enterprises and products innovate and effectively go overseas with localized communication strategies and optimal advertising delivery.",
      text3: "International top new media platform",
      text4: "visualized outdoor digital advertising",
    },
    list: {
      text1: "More than 150 TV stations",
      text2: "More than 380 radio stations",
      text4: "1 million screens",
      text3: "7680 bus tracks",
      text5: "More than 2000 subway lines",
      text6: "More than 50,000 newspapers",
      text7: "20,000 magazines",
    },
    media: {
      text1: "Outdoor digital screen media resources",

      imgText0: "New York Times Square",
      imgText1: "Las Vegas Harmon Big Screen",
      imgText2: "London Piccadilly Circus Screen",
      imgText3: "London Vauxhall Cross Screen",
      imgText4: "London Westfield Shopping Centre",
      imgText5: "Paris Arc de Triomphe",
      imgText6: "Paris La Defense Business District Screen",
      imgText7: "Paris Pedestrian Street Business District",
      imgText8: "Berlin City Centre Digital Screen",
      imgText9: "Frankfurt City Centre Digital Screen",
      imgText10: "Italy ∙ Rome Spanish Square",
      imgText11: "Ran Santa Barbara Square Screen",
      imgText12: "Sydney King's Cross Street Big Screen",
      imgText13: "Melbourne Bourke Street Shopping Centre LED",
    },
    release: "PR release",
  },
  // 案例
  case: {
    years: {
      text1: "Successful case",
      text2: "Creating value for customers",
    },
    social: {
      text1: "Social media + Live streaming",
      text2:
        "Build an internet social media position for fans interaction, live streaming monetization",
      text3:
        "Pandamobo MCN helps you accomplish social media matrix construction, live streaming script, positioning design, product selection, fan operation, and event planning. Help you quickly become a top monetized influencer.",
      text4: "Brand Value Category",
      text5: "Trendy 3C Category",
      text6: "Fast-Moving Consumer Goods Category",
    },
    kol: {
      text1: "KOL Business Order - Content Implantation",
      text2: "Improve influencer monetization market",
      imgText0:
        "Russia: Katerina Summer (YouTube) 19,352 subscribers https://www.youtube.com/channel/\n UC3PZeV3FCRpfraOZZGjWYHg\n 11-minute full product review\n including link to AliExpress\n promotion pages and promotion\n dates.",
      imgText1:
        "France:InsideCloset\n (Instagram)\n 75.4kfollowers\n https://www.instagram.com/in sidecloset/\n Willincludeproduct\n photography,link,short\n commentonInstagram\n channel.Awaitingapproval andproductfromclient.",
    },
    tiktok: {
      text1: "Assist TikTok Challenge",
      text2:
        "Pandamobo MCN\nAssist in promoting the Asian Games theme challenge in Indonesia",
      text3: "Exposure: 2 million +",
      text4:
        'Pandamobo MCN\nAssist in promoting \nTaiwan "The Tale of Yanxi Palace" Challenge',
      text5: "Exposure: 100,000 +",
    },
  },
  // 关于
  about: {
    years: {
      text1: "Pandamobo MCN",
      text2: "Listed for 14 years ",
    },
    profile: {
      text1: "Company Profile",
      text2:
        "Listed for 14 years Market cap $30 billion \nListed for 14 years, market cap $30 billion, close cooperation with TikTok for 5 years\nWith rich resources and a professional team, a stable capital chain, more efficient execution, and official authority certification, it provides more excellent and reliable service content for hundreds of cross-border enterprises and high-quality talents, building an industry-leading international MCN brand",
      text3: "family",
      text4: "service global customers ",
      text5: "online in-depth cooperation media",
      text6: "million",
      text7: "increase in influencers' exposure",
    },
    memorabilia: {
      text1: "Chronicle",
      listText0: "Founded in 1997",
      listText1: "Listed in 2009",
      listText2: "Established cooperation with all mainstream media in 2016",
      listText3: "Market value of ฿30 billion+ in 2023",
    },
    honors: {
      text1: "Industry \n Honors",

      text2: "Google",
      text3: "2016",
      text4: "YEAR",
      text5: "2016 China Mainland Fastest Growing Channel Partner",
      text6: "Google",

      text7: "GMIC",
      text8: "2016",
      text9: "YEAR",
      text10: "Globalization Most Anticipated Marketing Enterprise",
      text11: "GMIC",
      text12: "South China E-commerce Alliance",
      text13: "2016",
      text14: "YEAR",
      text15: "2016 South China E-commerce Alliance Excellent Partner",
      text16: "South China E-commerce Alliance",
      text17: "GMIC",
      text18: "2017",
      text19: "YEAR",
      text20: "Best Marketing Interaction Effect of the Year",
      text21: "GMIC",
      text22: "Golden Far Award",
      text23: "2017",
      text24: "YEAR",
      text25: "Most Marketing Innovative Enterprise",
      text26: "Golden Far Award",

      text27: "2018 Tiger Roar Award",
      text28: "2018",
      text29: "YEAR",
      text30: "2017-2018 Tiger Roar Award Government Agency Excellence Award",
      text31: "2018 Tiger Roar Award",

      text32: "Financial Point Global Business Innovation Award",
      text33: "2018",
      text34: "YEAR",
      text35: "Overseas Marketing Silver Award",
      text36: "Financial Point Global Business Innovation Award",

      text37: "China 4A",
      text38: "",
      text39: "",
      text40: "China 4A Council Unit",
      text41: "China 4A",

      text42: "ADMEN International Award",
      text43: "2019 ",
      text44: "YEAR",
      text45:
        "The Most Commercially Valuable Digital Marketing Company of the Year",
      text46: "ADMEN International Award",

      text47: "Golden Investment Award",
      text48: "2019",
      text49: "YEAR",
      text50: "Golden Investment Award Marketing Case Award",
      text51: "Golden Investment Award",

      text52: "MS Lingmou Award",
      text53: "2020",
      text54: "YEAR",
      text55: "2020 Top Ten Global Marketing Service Platforms",
      text56: "MS Lingmou Award",

      text57: "lobal Cross-border E-commerce Summit",
      text58: "2020",
      text59: "YEAR",
      text60: "Digital Empowerment Elite Top50",
      text61: "lobal Cross-border E-commerce Summit",
    },
  },
  contact: {
    text1: "contact \n information",
    text2: "liuzilin@pandamobo.com",
    text3: "linxufeng@pandamobo.com",
    text4: "yemeiyi@pandamobo.com",
  },
};
